<template>
  <div>
    <div class="vx-row">
      <div class="vx-col">
        <vs-button
          @click="addOffer"
          class="
            p-3
            mb-0
            mr-4
            rounded-lg
            cursor-pointer
            flex
            items-center
            justify-center
          "
        >
          <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
          <span>إضافة عرض</span>
        </vs-button>
      </div>
    </div>
    <div class="vx-row" v-if="offers.length">
      <vs-divider class="mb-12" />

      <div
        class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base"
        v-for="offer in offers"
        :key="offer.id"
      >
        <vx-card>
          <div class="my-6">
            <h5 class="mb-2 h2">{{ offer.name }}</h5>
            <p class="text mt-2">
              عدد المنتجات: <span>{{ offer.products_count }}</span>
            </p>
          </div>
          <vs-divider />
          <div class="flex justify-between flex-wrap">
            <span>
              <p class="text-xl">قيمة التخفيض</p>
              <p class="text-xl mb-2">
                {{ offer.value }}
                <span v-if="offer.is_percentage">%</span>
                <span v-else>د.ل.</span>
              </p>
            </span>
            <span>
              <p class="text-lg">تاريخ الإنتهاء</p>
              <p class="text-grey">{{ offer.expire_date }}</p>
            </span>
          </div>
          <vs-divider />
          <div class="flex justify-between flex-wrap">
            <vs-button
              class="mt-4"
              type="border"
              color="#D32F2F"
              @click="deleteOffer(offer.id)"
              >حذف</vs-button
            >
            <router-link :to="{ name: 'edit-offer', params: { id: offer.id } }">
              <vs-button
                class="mt-4 mr-2 shadow-lg primary"
                type="gradient"
                gradient-color-secondary="#CE9FFC"
                >تعديل</vs-button
              >
            </router-link>
          </div>
        </vx-card>
      </div>
    </div>
    <EmptyState
      :link="addOffer"
      page="عروض"
      display="true"
      v-else
    ></EmptyState>
  </div>
</template>

<script>
import moduleOffer from "@/store/offers/moduleOffer.js";
import EmptyState from "@/views/emptyState.vue";

export default {
  components:{
    EmptyState
  },
  data() {
    return {
      offers: [],
      deleteOfferID: "",
    };
  },
  methods: {
    addOffer() {
      this.$router.push("offers/new");
    },
    editOffer() {
      this.$router.push("offers/edit");
    },
    deleteOffer(id) {
      this.deleteOfferID = id;
      this.$vs.dialog({
        title: "حذف العرض",
        type: "confirm",
        text: ".سيتم حذف هذا العرض من جميع المنتجات، لا يمكنك التراجع عن هذه الخطوة",
        color: "danger",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.archiveOffer,
      });
    },
    archiveOffer() {
      this.$vs.loading();
      this.$store
        .dispatch("offer/archiveOffer", this.deleteOfferID)
        .then(() => {
          this.getData(1);
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم حذف العرض  بنجاح",
            color: "success",
          });
        })
        .catch((error) => {
          this.errorDialog(error);
        });
        this.$vs.loading.close();

    },
    getData(page) {
      this.$store.dispatch("offer/fetchOffers", page).then((response) => {
        this.offers = response.data;
        this.$vs.loading.close();
      });
    },
  },
  created() {
    this.$vs.loading();
    if (!moduleOffer.isRegistered) {
      this.$store.registerModule("offer", moduleOffer);
      moduleOffer.isRegistered = true;
    }
    this.getData(1);
  },
};
</script>
